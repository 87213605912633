export async function fileToDataUrl(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result as string);
      },
      false
    );
    reader.addEventListener('error', (error) => {
      reject(error);
    });
    reader.readAsDataURL(file);
  });
}

export async function urlToFile(url: string): Promise<File> {
  // NOTE: url must have CORS configured for this to work
  const response = await fetch(url);
  const file = await response.blob();
  return file as File;
}

export function isDataUrl(url: string): boolean {
  return url?.startsWith('data:');
}

export async function ensureDataUrl(imageUrl: string) {
  if (isDataUrl(imageUrl)) {
    return imageUrl;
  }
  try {
    const file = await urlToFile(imageUrl);
    const imageDataUrl = await fileToDataUrl(file);
    return imageDataUrl;
  } catch (err) {
    console.error(err);
  }
}

export function base64EncodedDataUrlToBuffer(base64EncodedDataUrl: string): {
  buffer: Buffer;
  contentType: string;
} {
  const [meta, base64String] = base64EncodedDataUrl.split(',');
  const buffer = Buffer.from(base64String, 'base64');
  const contentType = meta.split(':')[1].split(';')[0];
  return {
    buffer,
    contentType,
  };
}
