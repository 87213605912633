import { parseSalePlatform, salePlatformsInfo } from '@sportscardinvestor/schemas';
import MarketplaceLogoUI, {
  MarketplaceLogoProps as MarketplaceLogoUIProps,
} from '../../sci-ui-components/sales/MarketplaceLogo/MarketplaceLogo';

export type MarketplaceLogoProps = Omit<
  MarketplaceLogoUIProps,
  'marketplace' | 'logoUrl' | 'sellerName' | 'isKnownSeller' | 'platfromId'
>;

export function MarketplaceLogo({ platformId, ...rest }: MarketplaceLogoProps) {
  const info = salePlatformsInfo[platformId === 'raw' ? parseSalePlatform({ platformName: platformId }) : platformId];
  return (
    <MarketplaceLogoUI
      {...rest}
      platformId={platformId}
      marketplace={platformId}
      logoUrl={info.logoUrl}
      isKnownSeller={info.isKnownSeller}
      sellerName={info.sellerName}
    />
  );
}
