import ImageUpload, { ImageUploadProps } from '../ImageUpload/ImageUpload';
import { CollectibleType } from '../../types/collectibleType';
import CollectibleImage, { CollectibleImageSkeleton } from '../../collectibles/CollectibleImage/CollectibleImage';

export interface CollectibleImageUploadProps extends Omit<ImageUploadProps, 'ImageComponent'> {
  collectibleType: CollectibleType;
  isLoading?: boolean;
}

export default function CollectibleImageUpload({ collectibleType, isLoading, ...props }: CollectibleImageUploadProps) {
  return (
    <ImageUpload
      ImageComponent={
        isLoading ? (
          <CollectibleImageSkeleton collectibleType={collectibleType} />
        ) : (
          <CollectibleImage
            url={props.imageUrl ?? props.defaultImageUrl}
            collectibleType={collectibleType}
            alt={props.imageUrl ? 'New image' : 'Current image'}
          />
        )
      }
      {...props}
    />
  );
}
