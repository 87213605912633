import { DetailedHTMLProps, HTMLAttributes, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { gradedSportsCardAspectRatio, sportsCardAspectRatio } from '../../sport-cards/constants';
import { ExtendedCollectibleType } from '../../types/collectibleType';
import classes from './CollectibleImage.module.scss';
import { useIsTylerMode } from 'sci-ui-components/0401/useTylerMode';
import { useOnValueChange } from '@/hooks/useOnValueChange';

export type AspectRatio = [number, number]; // width to height

export const aspectRatiosMap: Record<ExtendedCollectibleType, AspectRatio> = {
  'sports-card': sportsCardAspectRatio,
  'sealed-wax-card': sportsCardAspectRatio,
  'graded-sports-card': gradedSportsCardAspectRatio,
};
const placeholdersMap: Record<ExtendedCollectibleType, string> = {
  'sports-card': '/images/sports-card-no-image.jpg',
  'sealed-wax-card': '/images/sports-card-no-image.jpg',
  'graded-sports-card': '/images/sports-card-no-image.jpg',
};

export type CollectibleImageSize = 'small' | 'medium' | 'large' | 'original';

export type CollectibleImageProps = {
  url: string | undefined | null;
  collectibleType: ExtendedCollectibleType;
  alt: string;
  size?: CollectibleImageSize;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function getAspectRatio(collectibleType: ExtendedCollectibleType) {
  return aspectRatiosMap[collectibleType];
}

export function getPlaceholder(collectibleType: ExtendedCollectibleType) {
  return placeholdersMap[collectibleType];
}

export default function CollectibleImage({
  alt,
  collectibleType,
  url,
  className,
  size,
  ...otherProps
}: CollectibleImageProps) {
  const imgRef = useRef<HTMLImageElement>(null);
  const responsiveUrl = useMemo(() => getResponsiveCollectibleUrl(url, size), [url, size]);
  const [isResponsiveUrl, setIsResponsiveUrl] = useState(responsiveUrl !== url);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(() => (responsiveUrl ? isCached(responsiveUrl) : false));
  const tylerMode = useIsTylerMode();
  const aspectRatio = getAspectRatio(collectibleType);
  const placeholderUrl = getPlaceholder(collectibleType);
  useOnValueChange(url, () => {
    // NOTE: reset state when url changes
    setError(false);
    setIsResponsiveUrl(responsiveUrl !== url);
    setLoaded(responsiveUrl ? isCached(responsiveUrl) : false);
  });

  let finalImageUrl = isResponsiveUrl ? responsiveUrl : url;
  if (tylerMode) {
    finalImageUrl = 'https://sportscardinvestor.s3.amazonaws.com/prod/custom/15039_242889-L';
  }

  return (
    <div
      {...otherProps}
      className={clsx(classes.root, className)}
      style={{
        ...(otherProps.style ?? {}),
        aspectRatio: `${aspectRatio[0]}/${aspectRatio[1]}`,
      }}
    >
      <img
        key={url} // NOTE: to force react to re-create element on url change so that old image is not displayed while new one is loading
        ref={imgRef}
        alt={alt}
        src={error ? placeholderUrl : finalImageUrl ?? placeholderUrl}
        className={clsx(classes.image, { [classes.loading]: !loaded })}
        onLoad={() => {
          setLoaded(true);
        }}
        onError={() => {
          if (isResponsiveUrl) {
            setIsResponsiveUrl(false);
          } else {
            setError(true);
          }
          setLoaded(false);
        }}
        loading="lazy"
      />
    </div>
  );
}

export function CollectibleImageSkeleton({
  collectibleType,
  className,
}: Pick<CollectibleImageProps, 'className' | 'collectibleType'>) {
  const aspectRatio = getAspectRatio(collectibleType);
  return (
    <div
      className={clsx(classes.root, 'animate-pulse bg-lightBg-darkGrey dark:bg-darkBg-darkGrey', className)}
      style={{
        aspectRatio: `${aspectRatio[0]}/${aspectRatio[1]}`,
      }}
    />
  );
}

function isCached(src: string): boolean {
  const image = new Image();
  image.src = src;
  return image.complete;
}

function getResponsiveCollectibleUrl(url: string | null | undefined, size?: CollectibleImageSize) {
  if (!size || !url) {
    return url;
  }
  const [urlBeforeQuery, query] = url.split('?');
  if (urlBeforeQuery.replace(/^.*\//, '').includes('.') && !url.includes('custom')) {
    // url has extension -> do not try to modify
    return url;
  }
  const urlWithoutSize = urlBeforeQuery.replace(/-[lms]$/i, '');
  return `${urlWithoutSize}${getSizeSuffix(size)}${query?.length ? `?${query}` : ''}`;
}

function getSizeSuffix(size: CollectibleImageSize) {
  switch (size) {
    case 'large':
      return '-L';
    case 'small':
      return '-S';
    case 'medium':
      return '-M';
    case 'original':
      return '';
    default:
      return '';
  }
}
