import React, { ReactNode } from 'react';
import clsx from 'clsx';
import {
  CollectibleType,
  CompletedSaleListingType,
  isCompletedSaleAuctionListingType,
  parseSalePlatform,
  SalePlatform,
} from '@sportscardinvestor/schemas';
import { LinkButton } from '@sportscardinvestor/sci-ui/components/buttons/link-button';
import CollectibleImage from '../../sci-ui-components/collectibles/CollectibleImage/CollectibleImage';
import { CurrentPriceInfo } from '../collectibles/CollectibleItem';
import { MarketplaceLogo } from './MarketplaceLogo';
import { listingTypeNames } from '@/features/admin/sales/completed/constants';
import { marketplaceHelpers } from '@/services/marketplaceHelpers';

export interface SaleItemUIProps {
  collectibleType: CollectibleType;
  className?: string;
  size?: SalesHistoryItemSize;
  rightContent?: ReactNode;
  bottomContent?: ReactNode;
  listingType: CompletedSaleListingType;
  saleUrl: string | null;
  saleTitle: string;
  imageUrl: string | null;
  finalPrice: number;
  saleDate: string | null;
  platformId: SalePlatform;
  linkTitle?: boolean;
  showListingType?: boolean;
}
export type SalesHistoryItemSize = 'md' | 'lg';

export function SaleItemUI({
  collectibleType,
  className,
  size = 'md',
  rightContent,
  bottomContent,
  listingType,
  saleUrl,
  saleTitle,
  imageUrl,
  finalPrice,
  saleDate,
  platformId,
  showListingType,
  linkTitle,
}: SaleItemUIProps) {
  const listingTypeName = listingType ? listingTypeNames[listingType] : null;
  const title = (
    <h4 className={clsx('w-full line-clamp-3 text-start font-semibold text-base text-inherit')}>{saleTitle}</h4>
  );

  return (
    <div
      className={clsx(
        'twp w-full flex',
        {
          'gap-3': size === 'md',
          'gap-4': size === 'lg',
        },
        className
      )}
    >
      <CollectibleImage
        className={clsx('grow-0 shrink-0', {
          'w-20': size === 'md',
          'w-28': size === 'lg',
        })}
        url={imageUrl}
        collectibleType={collectibleType}
        alt={saleTitle}
      />
      <div className="grow shrink flex flex-col items-start justify-center gap-1">
        {!!linkTitle && !!saleUrl ? (
          <LinkButton
            target="_blank"
            href={marketplaceHelpers.makeDirectItemUrl({
              marketplace: platformId,
              url: saleUrl,
              listingType: isCompletedSaleAuctionListingType(listingType) ? 'auction' : 'fixed-price',
            })}
            className="text-brand border-none"
            size="custom"
          >
            {title}
          </LinkButton>
        ) : (
          <>{title}</>
        )}
        <div className="w-full flex items-center justify-start gap-4 flex-wrap">
          <CurrentPriceInfo currentPrice={finalPrice} currentPriceDate={saleDate} size={size} />
          <MarketplaceLogo platformId={parseSalePlatform({ platformName: platformId })} size="sm" />
          {!!listingTypeName && !!showListingType && <span>{listingTypeName}</span>}
        </div>
        {bottomContent}
      </div>
      {rightContent}
    </div>
  );
}
