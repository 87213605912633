import { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { salePlatformNames, SalePlatformInfo, SalePlatform } from '@sportscardinvestor/schemas';

import classes from './MarketplaceLogo.module.scss';

export type MarketplaceLogoProps = Pick<
  SalePlatformInfo,
  'logoUrl' | 'marketplace' | 'sellerName' | 'isKnownSeller'
> & {
  platformId: SalePlatform;
  displaySellerName?: boolean;
  size?: 'md' | 'sm';
} & DetailedHTMLProps<HTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export default function MarketplaceLogo({
  className,
  logoUrl,
  marketplace,
  sellerName,
  displaySellerName = false,
  isKnownSeller,
  size = 'md',
  platformId,
  ...otherProps
}: MarketplaceLogoProps) {
  return (
    <label className={clsx(classes.root, className)}>
      <img
        {...otherProps}
        alt={`${platformId === 'raw' ? '' : salePlatformNames[platformId]} ${sellerName ?? ''}`}
        src={logoUrl ?? '/icons/platforms/ebay.png'}
        className={clsx(classes.image, {
          [classes.md]: size === 'md',
          [classes.sm]: size === 'sm',
        })}
        loading="lazy"
      />
      {!!displaySellerName && !!sellerName && !isKnownSeller && <span className={classes.name}>{sellerName}</span>}
    </label>
  );
}
