import CollectibleImageUpload, {
  CollectibleImageUploadProps,
} from '../../sci-ui-components/forms/CollectibleImageUpload/CollectibleImageUpload';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { showError } from '../../services/toaster';
import { cropCollectibleImage } from '@/features/images/CropImageDialog';

export function CustomCollectiblePhotoSelector({
  id,
  collectibleType,
  onChange,
  value,
  allowReCrop,
  isLoading,
  ...rest
}: {
  id: string;
  collectibleType: CollectibleType;
  value: string | null;
  onChange: (newImageUrl: string | null) => void;
  className?: string;
  allowReCrop?: boolean;
} & Pick<CollectibleImageUploadProps, 'buttonsSize' | 'changeButtonPosition' | 'isLoading'>) {
  return (
    <CollectibleImageUpload
      {...rest}
      collectibleType={collectibleType}
      defaultImageUrl={value}
      imageUrl={value}
      onChange={async (selection) => {
        if (!selection) {
          return;
        }
        const croppedImage = await cropCollectibleImage({
          collectibleType,
          imageUrl: selection.imageDataUrl,
        });
        if (croppedImage) {
          onChange(croppedImage);
        }
      }}
      onReCrop={
        !!value && !!allowReCrop
          ? async (imageUrl) => {
              const croppedImage = await cropCollectibleImage({
                collectibleType,
                imageUrl,
              });
              if (croppedImage) {
                onChange(croppedImage);
              }
            }
          : undefined
      }
      onError={(error) => {
        showError({
          description: error?.message ?? 'Failed to select an image',
        });
      }}
      isLoading={isLoading}
    />
  );
}
